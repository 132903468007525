// Latvian
export default{
    form: {
        startSurveyButton: "Sāksim",
        nextButton: "Nākamais",
        submitAnswerButton: "Iesniegt atbildi",
        continueButton: "Turpināt",
        finishButton: "Pabeigt",
        headerPercentageCompletion: 'pabeigts',
        headerQuestionsCompleted: 'jautājumi',
        headerDefaultErrorMessage: 'Ups! Kaut kas nogāja greizi.',
        brandingPoweredBy: 'Darbina',
        brandingPromoWhySurvey: 'Kāpēc veikt aptauju, ja varat izmantot <b>{product}</b>?',
        brandingPromoGetStarted: 'Sāciet jau šodien!',
        submissionPending: "Voiceform tiek iesniegta. Lūdzu, neaizveriet pārlūkprogrammas logu.",
        submissionCanCloseWindow: "Jūsu voiceform veiksmīgi iesniegta. Tagad varat aizvērt logu 👍",
        mediaAttachmentOverlayCloseButton: "Aizvērt",
        expiredFormHeader: "Paldies, ka apmeklējāt!",
        expiredFormMessage: "Šķiet, ka šī aptauja ir beigusies. Lūdzu, sazinieties ar voiceform veidotāju, lai to paziņotu.",
        notPublishedFormHeader: "Voiceform nav publicēts!",
        notPublishedFormMessage: "Šķiet, ka šī aptauja nav publicēta. Lūdzu, sazinieties ar voiceform veidotāju, lai to paziņotu.",
        notPublishedFormRetryButton: "Mēģināt vēlreiz",
        errorFormHeader: "Ups!",
        errorFormMessage: "Kaut kas, iespējams, nogāja greizi. Lūdzu, mēģiniet vēlāk vēlreiz.",
        errorFormRetryButton: "Mēģināt vēlreiz",
        emptyFormHeader: "Paldies, ka apmeklējāt!",
        emptyFormMessage: "Ups! Šis veidlapa šobrīd šķiet tukša, bez jautājumiem vai lauciņiem aizpildīšanai. Atvainojamies par sagādātajām neērtībām. Lūdzu, sazinieties ar šīs veidlapas veidotāju, lai informētu par problēmu. Paldies par sapratni un sadarbību!",

        restoreFormHeader: "Izskatās, ka jums ir nepabeigta veidlapa!",
        restoreFormMessage: "Vai vēlaties turpināt no vietas, kur pārtraucāt?",
        restoreFormButton: "Turpināt veidlapu",
        restoreFormButtonNew: "Sākt no jauna",

        audioPermissionHeader: "Vai vēlaties atbildēt ar balsi?",
        audioPermissionMessage: "Runāšana padarīs atbildēšanu ātru un vienkāršu.",
        audioPermissionAcceptButton: "Jā, vēlos",
        audioPermissionDenyButton: "Nē, paldies",
        audioPermissionErrorHeader: "Mikrofons nav pieejams",
        audioPermissionErrorMessage: "Lūdzu, pārbaudiet pārlūkprogrammas atļaujas, lai iespējotu piekļuvi mikrofonam.",
        audioPermissionErrorButton: "Labi",
        audioRecorderFailedUpload: "Neizdevās augšupielādēt ierakstu",
        silenceDetectorError: "Mēs jūs nedzirdam. Lūdzu, pārbaudiet mikrofona iestatījumus. Padoms: iespējams, izmantojat ārējo mikrofonu.",
        audioRecorderButtonRecord: "Sākt ierakstu",
        audioRecorderButtonRecordMore: "Ierakstīt vairāk",
        voiceResponseSelectionMessage: "Izvēlieties, kā vēlaties atbildēt...",
        voiceResponseSelectionOrDivider: "vai",
        voiceResponseTextInputPlaceholder: "Ievadiet atbildi",
        voiceResponseTextInputCharacterCounterMinMessage: "Lūdzu, ievadiet vismaz {min} rakstzīmes",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} rakstzīmes (minimums {min} rakstzīmes)",
        voiceResponseTextInputCharacterValidationMessage: "{count} rakstzīmes (minimums {min} rakstzīmes)",

        // checkbox
        checkboxValidationTooFew: "Jums jāizvēlas vismaz {min} opcija(s)",
        checkboxValidationTooMany: "Jums jāizvēlas ne vairāk kā {max} opcija(s)",
        checkboxNoneOfTheAboveOption: "Neviens no iepriekš minētajiem",
        checkboxInvalidAnswer: "Nederīga atbilde.",

        // datepicker
        datePickerPlaceholder: "Izvēlieties datumu",

        // dropdown
        // email
        emailLabel: "E-pasts",
        emailInvalid: "Nederīgs e-pasts.",
        // file-upload
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Ierakstīt video",
        fileUploadPluginScreenCast: "Ekrāna ieraksts",
        fileUploadVideoImportFilesDefault: 'Ierakstiet vai augšupielādējiet video, izmantojot:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Izvēlieties video ierakstīšanas opciju:',
        fileUploadVideoImportFilesNoCamera: 'Nospiediet pogu, lai ierakstītu ekrāna video',
        fileUploadVideoImportFilesNoScreenCast: 'Nospiediet pogu, lai ierakstītu video',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Nav pieejamu opciju video ierakstīšanai',

        // matrix
        matrixValidationMessage: "Atbilde ir derīga.",
        matrixRow: "Rinda",
        matrixColumn: "Kolonna",
        matrixRowRequired: "Rinda {rowTitle} ir nepieciešama.",
        matrixRadioGroup: "Radio grupa",
        matrixCheckboxGroup: "Atzīmju grupa",
        matrixGroupRequired: "Rindai {rowTitle}. {type} {groupTitle} ir nepieciešams.",
        matrixColumnRequired: "Rindai {rowTitle}. Kolonna {columnTitle} ir nepieciešama.",
        matrixColumnInvalid: "Rindai {rowTitle}. Kolonna {columnTitle} ir nederīga.",
        matrixRequired: "Nepieciešams.",
        matrixNumericMustBeNumber: "Jābūt skaitlim.",
        matrixNumericMustBeGreaterThenMin: "Jābūt lielākam par {min}.",
        matrixNumericMustBeLessThenMax: "Jābūt mazākam par {max}.",
        matrixNumericMustBeInteger: "Jābūt veselam skaitlim.",
        matrixNumericInvalidNumber: "Nederīgs skaitlis.",

        // name
        nameLabel: "Vārds",
        nameInvalid: "Norādiet vārdu un uzvārdu.",

        // nps
        npsNotLikely: "Vispār nav ticams",
        npsExtremelyLikely: "Ļoti ticams",

        // numeric input
        numericInputRequired: "Nepieciešams.",
        numericInputMustBeNumber: "Jābūt skaitlim.",
        numericInputMustBeGreaterThenMin: "Jābūt lielākam par {min}.",
        numericInputMustBeLessThenMax: "Jābūt mazākam par {max}.",
        numericInputMustBeInteger: "Jābūt veselam skaitlim.",
        numericInputInvalidNumber: "Nederīgs skaitlis.",
        numericInputPlaceholder: "Ievadiet skaitli",

        // phone
        phoneInvalid: "Tālruņa numurs ir nederīgs.",
        phoneCountrySelectorLabel: 'Valsts kods',
        phoneCountrySelectorError: 'Izvēlieties valsti',
        phoneNumberLabel: 'Tālruņa numurs',
        phoneExample: 'Piemērs:',

        // boolean
        booleanYesLabel: "Jā",
        booleanNoLabel: "Nē",

        //questionStep
        questionStepAudioQuestionLabel: "Audio jautājums",

        // errors
        invalidPhoneNumber: "{phone} ir nederīgs tālruņa numurs.",
        invalidEmail: "{email} ir nederīga e-pasta adrese.",
        questionIsRequired: "Jautājums ir obligāts.",
        answerIsNotValid: "Atbilde nav derīga.",
        unfinishedProbingDialogError: "Lūdzu, pabeidziet dialogu.",
        cannotResumePartialResponse: "Nevar atsākt daļēju atbildi.",
        failedToSubmitForm: "Neizdevās iesniegt veidlapu. Pārbaudiet interneta savienojumu un mēģiniet vēlreiz."
    }
}
